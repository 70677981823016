

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyC15xW5EC8JzSsAN6zJ5OBZBCEP3F4ElGw",
  authDomain: "badger-grades.firebaseapp.com",
  databaseURL: "https://badger-grades-default-rtdb.firebaseio.com",
  projectId: "badger-grades",
  storageBucket: "badger-grades.appspot.com",
  messagingSenderId: "521210045849",
  appId: "1:521210045849:web:7cf215a95fdefe53381f08",
  measurementId: "G-FMJGRHVXKR"
};

